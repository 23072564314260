import { DEFAULT_RETURN_PAGE, DEFAULT_TARGET_PAGE, DEFAULT_CONTACTS_RETURN_PAGE } from '@constants/historyStore.js';

const defaultState = {
  returnPage: DEFAULT_RETURN_PAGE,
  returnPageParams: {},
  targetPage: DEFAULT_TARGET_PAGE,
  contactsReturnPage: DEFAULT_CONTACTS_RETURN_PAGE,
};

const getters = {
  getLastPage(state) {
    return state.returnPage;
  },
  getLastPageParams(state) {
    return state.returnPageParams;
  },
  getContactsLastPage(state) {
    return state.contactsReturnPage;
  },
  getTargetPage(state) {
    return state.targetPage;
  },
};

const mutations = {
  SET_PAGE(state, { type, value }) {
    state[type] = value;
  },
};

const actions = {
  setReturnPage({ commit }, value = DEFAULT_RETURN_PAGE) {
    commit('SET_PAGE', { type: 'returnPage', value });
  },
  setReturnPageParams({ commit }, value = {}) {
    commit('SET_PAGE', { type: 'returnPageParams', value });
  },
  setContactsReturnPage({ commit }, value = DEFAULT_RETURN_PAGE) {
    commit('SET_PAGE', { type: 'contactsReturnPage', value });
  },
  setTargetPage({ commit }, value = DEFAULT_TARGET_PAGE) {
    commit('SET_PAGE', { type: 'targetPage', value });
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  mutations,
  actions,
};
