import i18n from 'i18next';
import ICU from 'i18next-icu';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import ResourcesToBackend from 'i18next-resources-to-backend';
import { LANGUAGES } from '@constants/languages.js';
import { LOCAL_STORAGE_ITEMS } from '@utils/common.js';

export const DEFAULT_LOCALE = 'cs';
export const DEFAULT_NAMESPACE = 'index';

const versions = {};
Object.values(LANGUAGES).forEach(L => {
  versions[L] = new URL(`./${L}/index.json`, import.meta.url).href;
});

i18n
  .use(ICU)
  .use(Backend)
  .init({
    backend: {
      backends: [LocalStorageBackend, ResourcesToBackend((lg, ns) => import(`@I18next/${lg}/${ns}.json`))],
      backendOptions: [
        {
          prefix: `${LOCAL_STORAGE_ITEMS.I18N_CACHE}_`,
          expirationTime: 7 * 24 * 60 * 60 * 1000,
          versions: versions,
          store: typeof window !== 'undefined' && !import.meta.env.DEV ? window.localStorage : null,
        },
      ],
    },
    // make sure that initialization will wait for first language
    initImmediate: false,
    partialBundledLanguages: true,
    ns: DEFAULT_NAMESPACE,
    fallbackLng: DEFAULT_LOCALE,
    lng: Object.values(LANGUAGES),
    resources: {},
    returnObjects: false,
  })
  .then();

export async function loadLanguageAsync(lang) {
  lang = lang || DEFAULT_LOCALE;
  if (i18n.languages?.includes(lang)) {
    await i18n.changeLanguage(lang);
    return;
  }

  try {
    await i18n.loadResources(lang);
    await i18n.changeLanguage(lang);
  } catch {
    console.warn(`${lang} is not supported`);
    return i18n.language;
  }
}

export const t = (key, options) => {
  if (typeof key !== 'string' && !(Array.isArray(key) && key.every(k => typeof k === 'string'))) {
    console.error(`[DGS] Wrong type of translation key. Except string or string array, "${typeof key}" was given.`);
  }
  if (key === 'default') {
    return key;
  }
  return i18n.t(key, options);
};

export function getLocale() {
  return i18n.language;
}
