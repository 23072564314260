import { PERMISSIONS } from '@utils/common.js';
import { route } from '@utils/routes';

const getFirstTwoPathSegments = path => {
  // example '/selfcare/envelopes/list' => '/selfcare/envelopes'
  return path.split('/').slice(0, 3).join('/');
};

const defaultState = {
  showSettingsSidebar: true,
  isIdentifyScenarioVersionsSidebarVisible: false,
  isSignatureScenarioVersionsSidebarVisible: false,
  isUserIntercomBooted: false,
  isVisitorIntercomBooted: false,
  lastIntercomUpdateTime: null,
  windowLoading: false,
  draggingContext: null,
  isOverflowing: false,
  scrollHeight: null,
  scrollToTop: 0,
  navigation: {
    main: [
      {
        route: 'selfcare.dashboard',
        title: 'dashboard.title',
        mobileLabel: false,
        mobileSection: true,
        subItem: false,
      },
      {
        route: 'selfcare.to_sign',
        title: 'toSign.toSign',
        mobileLabel: false,
        mobileSection: true,
        subItem: false,
      },
      {
        route: 'selfcare.envelopes.list',
        title: 'envelope.title',
        mobileLabel: true,
        mobileSection: true,
        subItem: false,
        permission: PERMISSIONS.ROLE_ENVELOPE_READ,
        parentTo: [getFirstTwoPathSegments(route('selfcare.envelopes.list')), '/selfcare/batch-sending'],
      },
      {
        route: 'selfcare.envelope_templates.list',
        title: 'envelopeTemplates.title',
        mobileLabel: false,
        mobileSection: true,
        subItem: false,
        permission: PERMISSIONS.ROLE_ENVELOPE_TEMPLATE_READ,
      },
      {
        route: 'selfcare.identifications.list',
        defaultRoute: 'selfcare.identifications.list',
        title: 'identifications.title',
        mobileLabel: false,
        mobileSection: true,
        subItem: false,
        permission: PERMISSIONS.ROLE_IDENTIFY_SCENARIO_WRITE,
        billingPermission: 'identify',
        parentTo: getFirstTwoPathSegments(route('selfcare.identifications.list')),
      },
      {
        route: 'selfcare.settings.personal',
        defaultRoute: 'selfcare.settings.personal',
        title: 'general.settings',
        mobileLabel: true,
        subItem: false,
        routePermission: PERMISSIONS.ROLE_USER_READ,
        parentTo: getFirstTwoPathSegments(route('selfcare.settings.personal')),
      },
      {
        route: 'selfcare.contacts.list',
        title: 'contacts.title',
        mobileLabel: false,
        subItem: true,
        couldBeHidden: true,
      },
      {
        route: 'selfcare.settings.personal',
        title: 'personal.title',
        mobileLabel: false,
        subItem: true,
        couldBeHidden: true,
      },
      {
        route: 'selfcare.settings.users.list',
        title: 'user.title',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_USER_READ,
      },
      {
        route: 'selfcare.settings.account',
        title: 'account.title',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_ACCOUNT_WRITE,
      },
      {
        route: 'selfcare.settings.brandings.list',
        title: 'branding.page.title',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_BRANDING_WRITE,
      },
      {
        route: 'selfcare.settings.envelope',
        title: 'envelope.envelopeTitle',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_ACCOUNT_WRITE,
      },
      {
        route: 'selfcare.settings.signature_scenarios.list',
        title: 'signatureScenarios.page.title',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_SIGNATURE_SCENARIO_WRITE,
      },
      {
        route: 'selfcare.settings.identify_scenarios.list',
        title: 'identifications.title',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_IDENTIFY_SCENARIO_WRITE,
        billingPermission: 'identify',
      },
      {
        route: 'selfcare.settings.messaging',
        title: 'messaging.title',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_ACCOUNT_WRITE,
      },
      {
        route: 'selfcare.settings.archive',
        title: 'archive.title',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_ACCOUNT_SECURITY_WRITE,
      },
      {
        route: 'selfcare.settings.certificates',
        title: 'certificates.page.title',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_CERTIFICATE_READ,
      },
      {
        route: 'selfcare.settings.security',
        title: 'settings-security.title',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_ACCOUNT_SECURITY_WRITE,
      },
      {
        route: 'selfcare.settings.apikeys.list',
        title: 'apikeys.title',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_APIKEY_READ,
      },
      {
        route: 'selfcare.settings.requests.list',
        title: 'requests.title',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_REQUEST_READ,
      },
      {
        route: 'selfcare.settings.webhooks.list',
        title: 'webhooks.title',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_WEBHOOK_READ,
      },
      {
        route: 'selfcare.settings.reports',
        title: 'reports.title',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_REPORT_READ,
      },
      {
        route: 'selfcare.settings.billing.manage',
        title: 'billing.title',
        mobileLabel: false,
        subItem: true,
        permission: PERMISSIONS.ROLE_ACCOUNT_WRITE,
        target: '_blank',
        isExternal: true,
      },
    ],
    settings: [
      {
        route: 'selfcare.settings.personal',
        title: 'personal.title',
        dataCy: 'settings-personal',
      },
      {
        route: 'selfcare.settings.users.list',
        title: 'user.title',
        dataCy: 'settings-user',
        permission: PERMISSIONS.ROLE_USER_READ,
      },
      {
        route: 'selfcare.settings.account',
        title: 'account.title',
        dataCy: 'settings-account',
        permission: PERMISSIONS.ROLE_ACCOUNT_WRITE,
      },
      {
        route: 'selfcare.settings.brandings.list',
        title: 'branding.page.title',
        dataCy: 'settings-branding',
        permission: PERMISSIONS.ROLE_BRANDING_WRITE,
      },
      {
        route: 'selfcare.settings.envelope',
        title: 'envelope.envelopeTitle',
        dataCy: 'settings-envelope',
        permission: PERMISSIONS.ROLE_ACCOUNT_WRITE,
      },
      {
        route: 'selfcare.settings.signature_scenarios.list',
        title: 'signatureScenarios.page.title',
        dataCy: 'settings-signature-scenarios',
        permission: PERMISSIONS.ROLE_SIGNATURE_SCENARIO_WRITE,
      },
      {
        title: 'navigation.identify',
        dataCy: 'settings-identify-collapse',
        collapse: {
          routes: [
            {
              route: 'selfcare.settings.identify_scenarios.settings',
              title: 'identifyScenariosSettings.page.title',
              dataCy: 'settings-identify-settings',
              permission: PERMISSIONS.ROLE_IDENTIFY_SCENARIO_WRITE,
            },
            {
              route: 'selfcare.settings.identify_scenarios.list',
              title: 'identifyScenarios.page.title',
              dataCy: 'settings-identify-list',
              permission: PERMISSIONS.ROLE_IDENTIFY_SCENARIO_WRITE,
            },
          ],
        },
      },
      {
        route: 'selfcare.settings.messaging',
        title: 'messaging.title',
        dataCy: 'settings-messaging',
        permission: PERMISSIONS.ROLE_ACCOUNT_WRITE,
      },
      {
        route: 'selfcare.settings.archive',
        title: 'archive.title',
        dataCy: 'settings-archive',
        permission: PERMISSIONS.ROLE_ACCOUNT_SECURITY_WRITE,
      },
      {
        route: 'selfcare.settings.certificates',
        title: 'certificates.page.title',
        dataCy: 'settings-certificates',
        permission: PERMISSIONS.ROLE_CERTIFICATE_READ,
      },
      {
        route: 'selfcare.settings.security',
        title: 'settingsSecurity.title',
        dataCy: 'settings-security',
        permission: PERMISSIONS.ROLE_ACCOUNT_SECURITY_WRITE,
      },
      {
        title: 'navigation.forDevelopers',
        dataCy: 'settings-for-developers-collapse',
        collapse: {
          routes: [
            {
              route: 'selfcare.settings.apikeys.list',
              title: 'apikeys.title',
              dataCy: 'settings-for-developers-apikeys',
              permission: PERMISSIONS.ROLE_APIKEY_READ,
            },
            {
              route: 'selfcare.settings.requests.list',
              title: 'requests.title',
              dataCy: 'settings-for-developers-requests',
              permission: PERMISSIONS.ROLE_REQUEST_READ,
            },
            {
              route: 'selfcare.settings.webhooks.list',
              title: 'webhooks.title',
              dataCy: 'settings-for-developers-webhooks',
              permission: PERMISSIONS.ROLE_WEBHOOK_READ,
            },
          ],
        },
      },
      {
        route: 'selfcare.settings.reports',
        title: 'reports.title',
        dataCy: 'settings-reports',
        permission: PERMISSIONS.ROLE_REPORT_READ,
      },
      {
        route: 'selfcare.settings.billing.manage',
        title: 'billing.title',
        dataCy: 'settings-billing',
        permission: PERMISSIONS.ROLE_ACCOUNT_WRITE,
        target: '_blank',
        isExternal: true,
      },
    ],
  },
};

const mutations = {
  SET_VALUE(state, { key, value }) {
    state[key] = value;
  },
  TRIGGER_SCROLL_TO_TOP(state) {
    state.scrollToTop++;
  },
};

const actions = {
  setValue({ commit }, data) {
    commit('SET_VALUE', data);
  },
  startFullscreenLoading({ commit }) {
    commit('SET_VALUE', { key: 'windowLoading', value: true });
  },
  stopFullscreenLoading({ commit }) {
    commit('SET_VALUE', { key: 'windowLoading', value: false });
  },
  hideSettingsSidebar({ commit }) {
    commit('SET_VALUE', { key: 'showSettingsSidebar', value: false });
  },
  revealSettingsSidebar({ commit }) {
    commit('SET_VALUE', { key: 'showSettingsSidebar', value: true });
  },
  userIntercomIsBooted({ commit }) {
    commit('SET_VALUE', { key: 'isUserIntercomBooted', value: true });
  },
  visitorIntercomIsBooted({ commit }) {
    commit('SET_VALUE', { key: 'isVisitorIntercomBooted', value: true });
  },
  updateIntercomUpdateTime({ commit }) {
    commit('SET_VALUE', { key: 'lastIntercomUpdateTime', value: Date.now() });
  },
  hideIdentifyScenarioVersionsSidebar({ commit }) {
    commit('SET_VALUE', { key: 'isIdentifyScenarioVersionsSidebarVisible', value: false });
  },
  showIdentifyScenarioVersionsSidebar({ commit }) {
    commit('SET_VALUE', { key: 'isIdentifyScenarioVersionsSidebarVisible', value: true });
  },
  hideSignatureScenarioVersionsSidebar({ commit }) {
    commit('SET_VALUE', { key: 'isSignatureScenarioVersionsSidebarVisible', value: false });
  },
  showSignatureScenarioVersionsSidebar({ commit }) {
    commit('SET_VALUE', { key: 'isSignatureScenarioVersionsSidebarVisible', value: true });
  },
  triggerScrollToTop({ commit }) {
    commit('TRIGGER_SCROLL_TO_TOP');
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
