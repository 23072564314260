const defaultState = {
  notifications: [],
  isWindowVisible: true,
  notificationsAlerts: false,
  systemNotificationsEnabled: false,
  beforeScrollTopClass: 'mt-16',
  afterScrollTopClass: '',
};
const state = {
  ...defaultState,
};

const getters = {};

const mutations = {
  SET_VALUE(state, { key, data }) {
    state[key] = data;
  },
  SET_DEFAULT_VALUES(state) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state = { ...defaultState, notifications: state.notifications };
  },
};

const actions = {
  setValue({ commit }, { key, data }) {
    commit('SET_VALUE', { key, data });
  },
  setDefaultValues({ commit }) {
    commit('SET_DEFAULT_VALUES');
  },
  addNotification({ commit }, data) {
    let alreadyExists = false;
    const id =
      data.id ||
      (window?.performance?.now ? `${Date.now()}${window.performance.now()}` : `${Date.now()}${Math.random()}`);
    for (const item of state?.notifications) {
      if (item.id && item.id === id) {
        alreadyExists = true;
      }
    }
    if (!alreadyExists) {
      if (state.systemNotificationsEnabled && !state.isWindowVisible && window?.Notification) {
        const helperDiv = document.createElement('div');
        helperDiv.innerHTML = data.message;
        const systemNotification = new Notification(`DigiSign - ${helperDiv.innerText}`);
        helperDiv?.remove?.();
        systemNotification.onclick = () => {
          window.focus();
        };
      }
      commit('SET_VALUE', {
        key: 'notifications',
        data: [
          {
            id,
            showTimestamp: false,
            type: 'warning',
            ...data,
          },
          ...state.notifications,
        ],
      });
    }
  },
  removeNotification({ commit }, id) {
    const notifications = [...state.notifications];
    for (const [i, val] of notifications.entries()) {
      if (val?.id && val.id === id) {
        notifications.splice(i, 1);
      }
    }
    commit('SET_VALUE', { key: 'notifications', data: notifications });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
