import Routing from '@root/vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min';
import routesJson from '@/routes.json';

Routing.setRoutingData(Object.freeze(routesJson));

export const routes = Object.keys(routesJson.routes).reduce(obj => {
  return obj;
}, {});

export const route = (value, params = {}, isAbsolute) => Routing.generate(value, params, isAbsolute);

export const getQueryParams = () => {
  const urlParams = {};
  const params = new URLSearchParams(window.location.search);
  for (const [key, value] of params.entries()) {
    if (urlParams[key]) {
      if (Array.isArray(urlParams[key])) {
        urlParams[key].push(value);
      } else {
        urlParams[key] = [urlParams[key], value];
      }
    } else {
      urlParams[key] = value;
    }
  }
  return urlParams;
};

const routeTests = {};

const bakeTests = () => {
  const routes = Routing.getRoutes();
  Object.entries(routes).forEach(([routeName, route]) => {
    routeTests[routeName] = new RegExp(
      Routing.generate(
        routeName,
        route?.tokens?.reduce?.(
          (acc, [type, , , token]) => ({ ...acc, ...(type === 'variable' ? { [token]: '(.*)' } : {}) }),
          {},
        ) ?? {},
      )
        .replace(/%28/g, '(')
        .replace(/%29/g, ')'),
    );
  });
};

export const getCurrentRoute = () => {
  const url = new URL(window.location.href);
  if (!Object.keys(routeTests).length) {
    bakeTests();
  }
  let longestTestMatch = '';
  let currentRouteName = '';

  Object.keys(routeTests).forEach(name => {
    if (routeTests[name].test(url.pathname)) {
      if (routeTests[name].toString().length > longestTestMatch.length) {
        currentRouteName = name;
        longestTestMatch = routeTests[name].toString();
      }
    }
  });
  return {
    name: currentRouteName,
    query: url.search,
    host: url.host,
    protocol: url.protocol,
    fullPath: url.href,
    path: url.pathname,
    hash: url.hash,
    routeTests,
    isCurrent: path => routeTests[currentRouteName].test(path),
    isChildOf: path =>
      Array.isArray(path) ? path.some(p => url.pathname.startsWith(p)) : url.pathname.startsWith(path),
  };
};
