export const BANK_ID_SCOPES_VALUES = {
  UNUSED: 'unused',
  OPTIONAL: 'optional',
  REQUIRED: 'required',
};

export const BANK_ID_PRODUCT = {
  CONNECT: 'connect',
  IDENTIFY: 'identify',
  IDENTIFY_PLUS: 'identify_plus',
  IDENTIFY_AML: 'identify_aml',
};

export const BANK_ID_SCOPES = {
  PROFILE_ADDRESSES: 'profile.addresses',
  PROFILE_BIRTHDATE: 'profile.birthdate',
  PROFILE_BIRTHNUMBER: 'profile.birthnumber',
  PROFILE_BIRTHPLACE_NATIONALITY: 'profile.birthplaceNationality',
  PROFILE_EMAIL: 'profile.email',
  PROFILE_GENDER: 'profile.gender',
  PROFILE_IDCARDS: 'profile.idcards',
  PROFILE_LEGALSTATUS: 'profile.legalstatus',
  PROFILE_MARITALSTATUS: 'profile.maritalstatus',
  PROFILE_NAME: 'profile.name',
  PROFILE_PAYMENT_ACCOUNTS: 'profile.paymentAccounts',
  PROFILE_PHONENUMBER: 'profile.phonenumber',
  PROFILE_TITLES: 'profile.titles',
  PROFILE_VERIFICATION: 'profile.verification',
};

export const BANK_ID_CLAIM = {
  NAME: 'name',
  GIVEN_NAME: 'given_name',
  MIDDLE_NAME: 'middle_name',
  FAMILY_NAME: 'family_name',
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
  TITLE_PREFIX: 'title_prefix',
  TITLE_SUFFIX: 'title_suffix',
  ADDRESS: 'address',
  ADDRESS_STREET: 'address_street',
  ADDRESS_CITY: 'address_city',
  ADDRESS_ZIP_CITY: 'address_zip_city',
  ADDRESS_ZIP: 'address_zip',
  ADDRESS_COUNTRY: 'address_country',
  BIRTHDATE: 'birthdate',
  AGE: 'age',
  BIRTHNUMBER: 'birthnumber',
  NATIONALITIES: 'nationalities',
  BIRTHPLACE: 'birthplace',
  GENDER: 'gender',
  ID_CARDS: 'idcards',
  MARITAL_STATUS: 'maritalstatus',
  LIMITED_LEGAL_CAPACITY: 'limited_legal_capacity',
  MAJORITY: 'majority',
  PAYMENT_ACCOUNTS: 'paymentAccounts',
  PEP: 'pep',
  VERIFICATION: 'verification',
};
