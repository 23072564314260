import { route } from '@utils/routes.js';
import formatDateString from '@utils/formatDateString.js';
import { getIconByExtensionName } from '@utils/extensions.js';

export default {
  install(app) {
    app.config.globalProperties.$route = route;
    app.config.globalProperties.$formatDateString = formatDateString;
    app.config.globalProperties.$getIconByExtensionName = getIconByExtensionName;
  },
};
