import LANGUAGES from '@I18next/languages.json';

const ENVELOPE_LANGUAGES = [
  LANGUAGES.CZECH,
  LANGUAGES.ENGLISH,
  LANGUAGES.SLOVAK,
  LANGUAGES.DUTCH,
  LANGUAGES.ROMANIAN,
  LANGUAGES.HUNGARIAN,
  LANGUAGES.POLISH,
];

export { ENVELOPE_LANGUAGES, LANGUAGES };
