export const EXTENSIONS = {
  PDF: 'application/pdf',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOC: 'application/msword',
  ODT: 'application/vnd.oasis.opendocument.text',
};

export function getIconByExtensionName(mimeType) {
  switch (mimeType) {
    case EXTENSIONS.PDF:
      return 'i_mimetype_pdf';
    case EXTENSIONS.DOCX:
      return 'i_mimetype_docx';
    case EXTENSIONS.ODT:
    case EXTENSIONS.DOC:
      return 'i_mimetype_doc';
    default:
      return 'i_mimetype_other';
  }
}
