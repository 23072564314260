import moment from 'moment';
import 'moment/dist/locale/cs';
import { format } from 'date-fns';
import { cs } from 'date-fns/locale';

moment.locale('cs');

/**
 * @deprecated The method should not be used
 */
export default function formatDateString(date, formatMask) {
  return moment(date).format(formatMask);
}

/**
 * uses different formatMask than moment.js
 * https://date-fns.org/docs/format
 */
export function formatDateTime(datetime, formatMask = 'Pp', options = { locale: cs }) {
  return format(new Date(datetime), formatMask, options);
}
