<template>
  <DsModal
    class="dsw focus:outline-none"
    :visible="visible"
    :aria-labelledby="id"
    :close-on-press-escape="$props.closeOnPressEscape"
    :class-modal="[
      'customBranding',
      mobileVariant ? 'h-full md:h-auto' : '',
      modalWidth,
      'relative max-h-full flex flex-col justify-center',
    ]"
    :close-on-click-modal="closeOnClickModal && !loading"
    @close="closeEvent"
  >
    <template #default="{ close }">
      <component
        :is="buttonActionType === 'button' ? 'div' : 'form'"
        class="relative flex flex-col self-center w-full overflow-auto transition-opacity duration-500"
        :class="[
          loading ? 'opacity-0 pointer-events-none' : 'opacity-100',
          classDropdownContent,
          backgroundStyle,
          mobileVariant ? 'md:shadow-lg md:my-4  h-full md:h-auto' : 'shadow-lg my-4',
        ]"
        @submit.prevent="$emit('confirm')"
      >
        <div class="flex flex-col flex-grow" :class="{ 'overflow-auto': scrollableContent }">
          <div class="flex justify-center items-center mx-4 mt-4 mb-4">
            <h1
              :id="id"
              class="flex-grow text-xl text-gray-1000"
              :class="{ 'mt-4': !removeTitleTopMargin }"
              tabindex="0"
            >
              <slot name="title">{{ title }}</slot>
            </h1>
            <button
              v-if="!hideCloseButton"
              :aria-label="$t('general.close')"
              class="flex items-center justify-center w-12 h-12 -mt-4 -mr-4 hover:bg-gray-200"
              type="button"
              @click.prevent="closeEvent"
            >
              <DsIcon name="i_close_remove" class="w-4 h-4 bg-gray-1000" />
            </button>
          </div>
          <div class="flex-grow px-4 text-sm" :class="[{ 'overflow-y-auto': scrollableContent }, contentBottomPadding]">
            <div :class="classContent">
              <slot name="content">
                {{ content }}
              </slot>
            </div>
          </div>
        </div>
        <div class="flex">
          <slot name="buttons">
            <AppButton
              class="flex-grow p-4 text-sm flex-base w-12/24"
              :class="{ invisible: hideSecondaryButton }"
              variant="secondary"
              tag="button"
              type="button"
              is-fat
              @click="handleSecondary(close)"
            >
              {{ $t(buttonCloseLabel) }}
            </AppButton>
            <AppButton
              class="flex-grow p-4 text-sm w-12/24"
              tag="button"
              :type="buttonActionType"
              :variant="buttonActionVariant"
              :disabled="buttonActionDisabled"
              :loading="buttonActionLoading"
              is-fat
              @click.prevent="$emit('confirm')"
            >
              {{ $t(buttonActionLabel) }}
            </AppButton>
          </slot>
        </div>
      </component>
      <div v-if="loading" class="absolute self-center transform translate-y-1/2 opacity-75 bottom-1/2">
        <DsProgressCircle :aria-label="`${$t('general.loading')}...`" is-indeterminate />
      </div>
    </template>
  </DsModal>
</template>

<script>
import { generateUid } from '@utils/common.js';
import AppButton from '@components/General/AppButton.vue';

export default {
  components: {
    AppButton,
  },
  props: {
    mobileVariant: {
      type: Boolean,
      default: false,
    },
    modalWidth: {
      type: String,
      default: 'w-full',
    },
    title: {
      type: String,
      default: '',
    },
    buttonActionLabel: {
      type: String,
      default: 'general.submit',
    },
    hideCloseButton: Boolean,
    hideSecondaryButton: Boolean,
    closeOnPressEscape: {
      type: Boolean,
      default: true,
    },
    closeOnClickModal: {
      type: Boolean,
      default: true,
    },
    buttonCloseLabel: {
      type: String,
      default: 'general.close',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    buttonActionType: {
      type: String,
      default: 'button',
    },
    buttonActionDisabled: {
      type: Boolean,
      default: false,
    },
    visible: Boolean,
    content: {
      type: String,
      default: '',
    },
    buttonActionVariant: {
      type: String,
      default: 'primary',
    },
    buttonActionLoading: {
      type: Boolean,
      default: false,
    },
    classDropdownContent: {
      type: String,
      default: 'md:max-w-lg w-full',
    },
    classContent: {
      type: String,
      default: 'md:w-19/24 tracking-medium',
    },
    removeTitleTopMargin: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: generateUid(),
    },
    scrollableContent: {
      type: Boolean,
      default: false,
    },
    contentBottomPadding: {
      type: String,
      default: 'pb-14',
    },
    backgroundStyle: {
      type: String,
      default: 'bg-white',
    },
    secondaryActionBlockCloseEmit: Boolean,
  },
  emits: ['confirm', 'secondary-action', 'close'],
  data() {
    return {
      blockCloseEmit: false,
    };
  },
  methods: {
    handleSecondary(closeCallback) {
      this.$emit('secondary-action');
      this.blockCloseEmit = true;
      closeCallback();
    },
    closeEvent() {
      if (this.blockCloseEmit && this.secondaryActionBlockCloseEmit) {
        this.blockCloseEmit = false;
        return;
      }
      this.$emit('close');
    },
  },
};
</script>
