import { reactive } from 'vue';

export default {
  install: (app, options = {}) => {
    const throttler = {
      timeout: null,
      timestamp: Date.now(),
    };
    const opt = {
      ...{ breakpoints: { width: {}, height: {} }, delay: 300 },
      ...options,
    };
    const reactComponent = reactive({
      currentWidth: null,
      currentHeight: null,
      currentWidthBreakpoint: null,
      currentHeightBreakpoint: null,
    });
    updateDimensions();

    const atLeastBreakpoint = (breakpoint, width = true) => {
      return (
        reactComponent[`current${width ? 'Width' : 'Height'}`] >=
        opt.breakpoints[width ? 'width' : 'height'][breakpoint]
      );
    };

    // provide methods for vue3 hooks
    app.provide('atLeastBreakpoint', atLeastBreakpoint);

    app.mixin({
      computed: {
        currentWidth: () => reactComponent.currentWidth,
        currentHeight: () => reactComponent.currentHeight,
        currentWidthBreakpoint: () => reactComponent.currentWidthBreakpoint,
        currentHeightBreakpoint: () => reactComponent.currentHeightBreakpoint,
      },
      methods: {
        atLeastBreakpoint,
        isBreakpoint(breakpoint, width = true) {
          return breakpoint === reactComponent[width ? 'currentWidthBreakpoint' : 'currentHeightBreakpoint'];
        },
        isInBreakpoints(breakpoints, width = true) {
          return breakpoints.contains(reactComponent[width ? 'currentWidthBreakpoint' : 'currentHeightBreakpoint']);
        },
      },
    });

    window.addEventListener('resize', () => {
      const nowStamp = Date.now();
      if (throttler.timestamp + opt.delay < nowStamp) {
        throttler.timestamp = nowStamp;
        updateDimensions();
      } else {
        clearTimeout(throttler.timeout);
        throttler.timeout = setTimeout(updateDimensions, opt.delay);
      }
    });

    function updateDimensions() {
      reactComponent.currentWidth = window.innerWidth;
      reactComponent.currentHeight = window.innerHeight;
      reactComponent.currentWidthBreakpoint = findBreakpoint(opt.breakpoints.width || {}, reactComponent.currentWidth);
      reactComponent.currentHeightBreakpoint = findBreakpoint(
        opt.breakpoints.height || {},
        reactComponent.currentHeight,
      );
    }

    function findBreakpoint(breakpoints, currentSize) {
      for (const [breakpoint, size] of Object.entries(breakpoints).sort((a, b) => (a[1] <= b[1] ? 1 : -1))) {
        if (size < currentSize) {
          return breakpoint;
        }
      }
      return null;
    }
  },
};

export function shallowObjectValuesToInt(object) {
  const parsedObject = {};
  for (const key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      parsedObject[key] = parseInt(object[key], 10);
    }
  }
  return parsedObject;
}
