import { createStore } from 'vuex';

import app from './app';
import notifications from './notifications';
import historyRedirect from './historyRedirect';

const debug = import.meta.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    notifications,
    app,
    historyRedirect,
  },
  strict: debug,
});
