<template>
  <component
    :is="tag"
    class="outline-none cursor-pointer group"
    :class="[
      getClassesByType(),
      sizeVariant(),
      getClassesByStatus(),
      leadingClass,
      getFlexAlignment,
      isFat ? fatHeight : '',
      { 'pr-12': rightPadding },
      { 'pointer-events-none': disabled || loading },
    ]"
    :disabled="disabled || loading"
    v-bind="$attrs"
  >
    <DsProgressCircle
      v-if="loading"
      :class="{ 'mr-3': !removeLoadingText }"
      :aria-label="`${$t(loadingMessage)}...`"
      is-indeterminate
      emphasize="2xs"
    />
    <template v-if="!loading">
      <slot v-if="!isIconType" />
      <DsIcon
        v-if="icon"
        :name="icon"
        class="w-3 h-3"
        :class="[{ 'ml-2': !isIconType }, getIconColorClass, classIcon]"
      />
    </template>
    <slot v-if="loading && !removeLoadingText" name="loading">
      <span v-if="loading" class="text-gray-900">{{ $t(loadingMessage) }}...</span>
    </slot>
  </component>
</template>

<script>
import { Link } from '@inertiajs/vue3';

const TYPES = {
  FILL: 'fill',
  HOLLOW: 'hollow',
  GHOST: 'ghost',
  ICON: 'icon',
  LINK: 'link',
};

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Link,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'a',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: 'general.wait',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    buttonType: {
      type: String,
      default: TYPES.FILL,
      validator: val => {
        const validValues = Object.values(TYPES);
        if (!validValues.includes(val)) {
          console.warn(`valid values for 'TYPE' are ${validValues}`);
        }
        return true;
      },
    },
    size: {
      type: String,
      default: 'base',
    },
    isFat: {
      type: Boolean,
      default: false,
    },
    fatHeight: {
      type: String,
      default: 'pb-7',
    },
    classIcon: {
      type: String,
      default: '',
    },
    leadingClass: {
      type: String,
      default: 'leading-6',
    },
    rightPadding: Boolean,
    removeLoadingText: Boolean,
  },
  computed: {
    isIconType() {
      return this.buttonType === TYPES.ICON;
    },
    getIconColorClass() {
      if (this.disabled) {
        if (this.buttonType === TYPES.GHOST) {
          return 'bg-gray-300';
        }
        return 'bg-gray-500';
      }
      if (this.buttonType === TYPES.GHOST) {
        return 'bg-blue-700';
      }
      if (this.buttonType === TYPES.HOLLOW) {
        return 'bg-blue-600 group-hover:bg-white';
      }
      return 'bg-white';
    },
    getFlexAlignment() {
      if (this.icon && !this.loading) {
        if (this.isIconType) return 'items-center justify-center';
        return 'items-center justify-between';
      }
      return '';
    },
  },
  methods: {
    sizeVariant() {
      const sizes = {
        '2xs': 'px-4 py-1 text-sm',
        'xs': this.buttonType === TYPES.ICON ? 'p-2' : 'px-4 py-2 text-sm',
        'sm': 'px-4 py-3 text-sm',
        'base': 'px-4 py-3 text-sm',
        'xl': 'px-4 py-5 text-sm',
      };
      return sizes[this.size];
    },
    getClassesByType() {
      const variants = {
        [TYPES.FILL]: {
          primary: 'inline-flex p-4 text-sm text-left',
          secondary: 'inline-flex p-4 text-sm text-left',
          danger: 'inline-flex p-4 text-sm text-left',
          empty: '',
        },
        [TYPES.HOLLOW]: {
          primary: 'inline-flex p-4 text-sm text-blue-600 outline-1 -outline-offset-1 outline-blue-600',
        },
        [TYPES.GHOST]: {
          primary: 'inline-flex p-4 text-sm justify-center',
          alternative: 'inline-flex p-4 text-sm',
        },
        [TYPES.ICON]: {
          primary: `inline-flex text-sm ${this.size ? '' : ' p-4 w-12 h-12'}`,
        },
        [TYPES.LINK]: {
          primary: 'inline-flex p-4 text-sm',
          secondary: 'inline-flex p-4 text-sm justify-start',
        },
      };
      return variants[this.buttonType][this.variant];
    },
    getClassesByStatus() {
      const fillStatuses = {
        primary: {
          base: 'text-white bg-blue-600 hover:bg-blue-650 active:bg-blue-800 active:shadow-primary focus:shadow-primary',
          loading: 'bg-transparent text-gray-600',
          disabled: 'bg-gray-300 text-gray-500 cursor-not-allowed',
        },
        secondary: {
          base: 'text-white bg-gray-800 hover:bg-gray-750 active:bg-gray-600 active:shadow-primary focus:shadow-primary',
          loading: '',
          disabled: 'bg-gray-300 text-gray-500 cursor-not-allowed',
        },
        danger: {
          base: 'text-white bg-red-600 hover:bg-red-650 active:bg-red-800 active:shadow-primary focus:shadow-primary',
          loading: '',
          disabled: 'bg-gray-300 text-gray-500 cursor-not-allowed',
        },
        empty: {
          base: '',
          loading: '',
          disabled: '',
        },
      };
      const variantsWithStatus = {
        [TYPES.FILL]: fillStatuses,
        [TYPES.HOLLOW]: {
          primary: {
            base: 'hover:text-white hover:bg-blue-650 active:bg-blue-800 active:shadow-primary focus:shadow-primary',
            loading: '',
            disabled: 'text-gray-500 cursor-not-allowed',
          },
        },
        [TYPES.GHOST]: {
          primary: {
            base: 'text-blue-600 hover:text-blue-700 hover:bg-gray-200 active:bg-gray-300 active:shadow-primary focus:shadow-primary',
            loading: '',
            disabled: 'text-gray-300 cursor-not-allowed border-gray-500',
          },
          secondary: {
            base: 'text-gray-600 hover:bg-gray-100 active:bg-gray-200 active:shadow-primary focus:shadow-primary',
            loading: '',
            disabled: 'text-gray-300 cursor-not-allowed border-gray-500',
          },
          alternative: {
            base: 'text-blue-600 hover:text-blue-700 hover:bg-gray-200 active:bg-gray-300 active:shadow-primary focus:shadow-primary',
            loading: '',
            disabled: 'text-gray-300 cursor-not-allowed border-gray-500',
          },
        },
        [TYPES.ICON]: fillStatuses,
        [TYPES.LINK]: {
          primary: {
            base: 'text-blue-600 hover:underline active:shadow-primary focus:shadow-primary',
            loading: '',
            disabled: '',
          },
          secondary: {
            base: 'text-gray-1000 hover:underline active:shadow-primary focus:shadow-primary',
            loading: '',
            disabled: '',
          },
        },
      };
      if (this.loading) {
        return variantsWithStatus[this.buttonType][this.variant].loading;
      }
      if (this.disabled) {
        return variantsWithStatus[this.buttonType][this.variant].disabled;
      }
      return variantsWithStatus[this.buttonType][this.variant].base;
    },
  },
};
</script>
